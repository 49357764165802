import * as React from 'react';
import Image from '../../atoms/Image/Image';
import { css } from '@emotion/react';
import Text from '../../atoms/Text/Text';
import { Link } from 'gatsby';
import Button from '../../atoms/Button/Button';
import { StaticImage } from 'gatsby-plugin-image';
import Iframe from '../../atoms/Iframe/Iframe';
import noLinkDerby from '../../../helper/noLinkDerby';
import Icon from '../../atoms/Icon/Icon';

export default function CardProyecto({
  keyEle,
  data,
  isProyecto,
  imageEleCss,
  classEle,
  tipo,
  isProjectsDelivered = false,
  styleCss,
}) {
  const urlData = !isProyecto
    ? data?.template?.proximoProyecto
    : data?.template?.proyectoEnVenta;

  const infoCss = css`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: calc(100vw * (calc(10 / var(--width_base))));
    margin-bottom: calc(100vw * (calc(9 / var(--width_base))));
  `;

  const iconInfoCss = css`
    &.gatsby-image-wrapper img {
      width: calc(100vw * (calc(10 / var(--width_base))));
      height: calc(100vw * (calc(10 / var(--width_base))));
    }
  `;

  const imageCss = css`
    height: calc(100vw * (calc(435 / var(--width_base))));
    width: 100%;
    @media (max-width: 767px) {
      height: calc(100vw * (calc(400 / var(--width_base))));
    }
    ${imageEleCss};
  `;

  const cardCss = css`
    // margin-bottom: calc(100vw * (calc(20 / var(--width_base))));
    color: white;
    display: block;
    // width: 23%;
    ${styleCss}
    @media (max-width: 767px) {
      // width: 100%;
      margin-bottom: initial;
    }
  `;

  const cardText = css`
    padding: calc(100vw * (calc(11.5 / var(--width_base))))
      calc(100vw * (calc(20 / var(--width_base))))
      calc(100vw * (calc(13 / var(--width_base))))
      calc(100vw * (calc(20 / var(--width_base))));
    background-color: #4a4a49;
    min-height: calc(100vw * (calc(284 / var(--width_base))));
    position: relative;

    @media (max-width: 767px) {
      padding: calc(100vw * (calc(20 / var(--width_base))))
        calc(100vw * (calc(20 / var(--width_base))))
        calc(100vw * (calc(15 / var(--width_base))))
        calc(100vw * (calc(20 / var(--width_base))));
      min-height: calc(100vw * (calc(200 / var(--width_base))));
      max-width: initial;
      margin-left: auto;
      margin-right: auto;
      margin-top: initial;
      background-color: #5e5e5e;
    }
  `;

  const distritoCss = css`
    background-color: #93ba1f;
    font-size: calc(100vw * (calc(14 / var(--width_base))));
    line-height: calc(100vw * (calc(14 / var(--width_base))));
    text-transform: uppercase;
    max-width: calc(100vw * (calc(146 / var(--width_base))));
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    padding-top: calc(100vw * (calc(10 / var(--width_base))));
    padding-bottom: calc(100vw * (calc(10 / var(--width_base))));
    margin-top: calc(100vw * (calc(11 / var(--width_base))));
    margin-bottom: calc(100vw * (calc(15 / var(--width_base))));
    @media (max-width: 767px) {
      margin-top: calc(100vw * (calc(15 / var(--width_base))));
      margin-bottom: calc(100vw * (calc(15 / var(--width_base))));
    }
  `;

  const directionCss = css`
    font-size: calc(100vw * (calc(14 / var(--width_base))));
    line-height: calc(100vw * (calc(14 / var(--width_base))));
  `;

  const estadoCss = css`
    font-size: calc(100vw * (calc(14 / var(--width_base))));
    line-height: calc(100vw * (calc(14 / var(--width_base))));
    text-align: center;
  `;

  const numCss = css`
    font-family: 'Montserrat Bold', sans-serif;
    font-size: calc(100vw * (calc(64 / var(--width_base))));
    line-height: calc(100vw * (calc(64 / var(--width_base))));
    margin-bottom: calc(100vw * (calc(15 / var(--width_base))));
    text-align: center;

    @media (max-width: 767px) {
      font-family: 'Montserrat Regular', sans-serif;
      margin-bottom: 0;
      font-size: calc(100vw * (calc(14 / var(--width_base))));
      line-height: calc(100vw * (calc(14 / var(--width_base))));
    }

    & span:not(:last-of-type) {
      margin-right: calc(100vw * (calc(30 / var(--width_base))));
      display: inline-block;
      position: relative;

      @media (max-width: 767px) {
        margin-right: calc(100vw * (calc(15 / var(--width_base))));
      }

      &::before {
        content: '';
        display: block;
        width: calc(100vw * (calc(8 / var(--width_base))));
        height: calc(100vw * (calc(8 / var(--width_base))));
        background-color: #93ba1f;
        border-radius: 50%;
        position: absolute;
        top: 0;
        bottom: 0;
        right: calc(100vw * (calc(-18 / var(--width_base))));
        margin-top: auto;
        margin-bottom: auto;

        @media (max-width: 767px) {
          width: calc(100vw * (calc(4 / var(--width_base))));
          height: calc(100vw * (calc(4 / var(--width_base))));
          right: calc(100vw * (calc(-9 / var(--width_base))));
        }
      }
    }
  `;

  const textCss = css`
    font-size: calc(100vw * (calc(14 / var(--width_base))));
    line-height: calc(100vw * (calc(14 / var(--width_base))));
  `;

  const text1Css = css`
    ${textCss};
    margin-bottom: calc(100vw * (calc(9 / var(--width_base))));

    @media (max-width: 767px) {
      margin-bottom: 0;
    }
  `;

  const text2Css = css`
    ${textCss};
    margin-bottom: calc(100vw * (calc(17 / var(--width_base))));
  `;

  const logoCss = css`
    height: calc(100vw * (calc(40 / var(--width_base))));
    width: calc(100vw * (calc(133 / var(--width_base))));
    margin-left: auto;
    margin-right: auto;
    @media (max-width: 767px) {
      width: auto;
      height: calc(100vw * (calc(33 / var(--width_base))));
    }
  `;

  const contentVideoCss = css`
    height: calc(100vw * (calc(435 / var(--width_base))));
    overflow: hidden;
  `;

  const videoCss = css`
    width: calc(100vw * (calc(900 / var(--width_base))));
    height: calc(100vw * (calc(900 / var(--width_base)))) !important;
    transform: translateX(-30%) translateY(-25%);
    pointer-events: none;
  `;

  return (
    <Link
      key={keyEle}
      css={cardCss}
      to={data?.uri ? data?.uri : '/'}
      className={`card-proyecto text-center ${
        data?.uri === '/proyecto/eco-derby/' && 'no-pointer'
      } ${classEle} project-item district-${data?.distritos?.nodes[0]?.slug}`}
    >
      {tipo === 'imagen' ? (
        <>
          {data?.featuredImage?.node ? (
            <>
              {data?.featuredImage?.node?.node ? (
                <Image
                  imagen={data?.featuredImage?.node?.node}
                  styleCss={imageCss}
                  classEle="card-imagen"
                />
              ) : (
                <Image
                  imagen={data?.featuredImage?.node}
                  styleCss={imageCss}
                  classEle="card-imagen"
                />
              )}
            </>
          ) : (
            <StaticImage
              css={imageCss}
              src="../../../images/sin-imagen.png"
              quality={100}
              formats={['auto', 'webp', 'avif']}
              alt="Proyecto sin imagen"
            />
          )}
        </>
      ) : (
        <>
          {data?.video?.urlVideo && (
            <div css={contentVideoCss}>
              <Iframe url={data?.video?.urlVideo} styleCss={videoCss} />
            </div>
          )}
        </>
      )}
      <div css={cardText} className="card-text">
        {urlData?.logoPortada && (
          <Image
            imagen={urlData?.logoPortada}
            styleCss={logoCss}
            objectFit="contain"
          />
        )}
        {data?.distritos?.nodes[0]?.name && (
          <Text
            styleCss={distritoCss}
            data={data?.distritos?.nodes[0]?.name}
            classEle="card-distrito"
          />
        )}
        <div css={infoCss} className="only-mov card-estado">
          <Icon typeEle="edificio" styleEle={iconInfoCss} />
          {data?.estados?.nodes[0]?.name && (
            <Text
              styleCss={estadoCss}
              data={data?.estados?.nodes[0]?.name}
              classEle="card-estado"
            />
          )}
        </div>
        <div css={infoCss} className="only-mov card-direccion">
          <Icon typeEle="jardin" styleEle={iconInfoCss} />
          {urlData?.direccionDescripcion && (
            <Text
              data={urlData?.direccionDescripcion}
              align="center"
              styleCss={directionCss}
              classEle="card-direction"
            />
          )}
        </div>
        {data?.dormitorios?.nodes.length > 0 && (
          <>
            <div css={numCss} className="card-num only-desk">
              {data?.dormitorios?.nodes &&
                data.dormitorios?.nodes.map((dormitorio, i) => (
                  <span key={i}>{dormitorio.name}</span>
                ))}
            </div>
            <div css={infoCss} className="only-mov card-dorm">
              <Icon typeEle="dorm" styleEle={iconInfoCss} />
              <div css={numCss} className="card-num">
                {data?.dormitorios?.nodes &&
                  data.dormitorios?.nodes.map((dormitorio, i) => (
                    <span key={i}>{dormitorio.name}</span>
                  ))}
              </div>

              <Text
                data="dormitorios"
                styleCss={text1Css}
                align="center"
                classEle="card-dorm"
              />
            </div>
            <div className="only-desk">
              <Text
                data="Dormitorios"
                styleCss={text1Css}
                align="center"
                classEle="card-dorm"
              />
            </div>

            {urlData?.precioDescripcion && (
              <div css={infoCss} className="only-mov card-price">
                <Icon typeEle="price" styleEle={iconInfoCss} />
                <Text
                  data={urlData?.precioDescripcion}
                  styleCss={text1Css}
                  align="center"
                  classEle="card-dorm"
                />
              </div>
            )}
          </>
        )}
        {urlData?.lanzamientoDescripcion && (
          <Text
            data={urlData?.lanzamientoDescripcion}
            styleCss={text2Css}
            align="center"
            classEle="card-status only-desk"
          />
        )}
        {!isProjectsDelivered && (
          <Button classEle="only-desk" typeEle="button" styleTypeEle="ver-mas">
            Ver más
          </Button>
        )}
      </div>
    </Link>
  );
}

CardProyecto.defaultProps = {
  isProyecto: true,
  tipo: 'imagen',
};
